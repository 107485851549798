<template>
  <div class="index">
    <MDBContainer class="py-5">
      <MDBRow>
        <MDBCol v-show="!nameForm">
          <vue-plyr ref="video"
                    class="player mt-0 mb-5"
                    :emit="['playing']"
                    :autoplay="false"
                    :muted="true"
                    :playsinline="true"
                    >
            <div class="plyr__video-embed" data-plyr-config='{"ratio": "16:9"}'>
              <iframe
                  :src=video.url
                  allow="accelerometer; autoplay"
                  allowfullscreen
              ></iframe>
            </div>
          </vue-plyr>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
    <MDBModal
        id="exampleModalCenter"
        tabindex="-1"
        labelledby="exampleModalCenterTitle"
        v-model="nameForm"
        centered
    >
      <MDBModalHeader>
        <MDBModalTitle id="exampleModalCenterTitle"> Welcome to Footprints Training Video for {{video.title}} </MDBModalTitle>
      </MDBModalHeader>
      <MDBModalBody>
        <p class="text-start">
          Footprints Behavioral Intervention is conducting a research study on the effectiveness of online
          training on professional development performance.
          <br><br>
          Your participation is confidential, and your name
          will not be mentioned in the report. Participation requires completion of the online training and
          medical documentation as part of your regular job requirements at Footprints Behavioral Intervention.
          <br><br>
          To allow your data to be used in the study, enter your name before watching the video. Your data will
          be used for research purposes only. Participants will be able to choose and receive a PDF copy of one
          of the reports generated by this study. <br><br><router-link to="consent" target="_blank">Click here to review the full consent form.</router-link>        </p>
        <MDBInput label="Enter name" type="text" v-model="name" />
      </MDBModalBody>
      <MDBModalFooter>
        <MDBBtn color="secondary" @click="submitName"> Go </MDBBtn>
      </MDBModalFooter>
    </MDBModal>
  </div>
</template>

<script>
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput
} from "mdb-vue-ui-kit";
import { ref } from 'vue'
import { addDoc, collection, updateDoc, db } from '@/firebase/init'

export default {
  name: "FootprintTrainingIndex",
  components: {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBModal,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
    MDBBtn,
    MDBInput
  },
  setup(){
    const nameForm = ref(false)
    return {
      nameForm
    }
  },
  data(){
    return {
      timer: 0,
      length: 0,
      duration: 0,
      name: '',
      watched: [],
      traineeId: '',
      video: {
        title: '',
        id: '',
        url: ''
      },
      videos: {
        'training1': {
          title: 'Module 1',
          id: 'training1',
          url: 'https://www.youtube-nocookie.com/embed/EIDDranF4S4?autoplay=0&amp;mute=0&amp;playsinline=1&amp;controls=0&amp;modestbranding=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https://footprintstrainingvideos.com'
        },
        'training2': {
          title: 'Module 2',
          id: 'training2',
          url: 'https://www.youtube-nocookie.com/embed/0govWfVZgaw?autoplay=0&amp;mute=0&amp;playsinline=1&amp;controls=0&amp;modestbranding=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https://footprintstrainingvideos.com'
        },
        'training3-1': {
          title: 'Module 3, Video 1',
          id: 'training3-1',
          url: 'https://www.youtube-nocookie.com/embed/rPVXoB9toSM?autoplay=0&amp;mute=0&amp;playsinline=1&amp;controls=0&amp;modestbranding=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https://footprintstrainingvideos.com'
        },
        'training3-2': {
          title: 'Module 3, Video 2',
          id: 'training3-2',
          url: 'https://www.youtube-nocookie.com/embed/mfxqmNo898Y?autoplay=0&amp;mute=0&amp;playsinline=1&amp;controls=0&amp;modestbranding=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https://footprintstrainingvideos.com'
        },
        'training4': {
          title: 'Module 4',
          id: 'training4',
          url: 'https://www.youtube-nocookie.com/embed/OAruRlGGoH4?autoplay=0&amp;mute=0&amp;playsinline=1&amp;controls=0&amp;modestbranding=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;origin=https://footprintstrainingvideos.com'
        },
      }
    }
  },
  created(){
    let params = new URLSearchParams(window.location.search)
    if (params.has('video')) {
      let videoInfo = params.get('video')
      this.video = {
        title: this.videos[videoInfo].title,
        url: this.videos[videoInfo].url,
        id: this.videos[videoInfo].id
      }
    }
  },
  mounted() {
    console.log('mounted')
    this.nameForm = true
    this.$refs.video.player.on('ready', () => this.getDuration())
    this.$refs.video.player.on('timeupdate', () => this.timeupdate())
    this.$refs.video.player.on('ended', () => this.syncFirebase())
    this.$refs.video.player.on('pause', () => this.syncFirebase())
  },
  methods: {
    getDuration(){
      console.log("meta data loaded")
      console.log("duration:" + this.$refs.video.player.duration)
      // get the duration in seconds, rounding up, to size the array
      var duration = parseInt(this.roundUp(this.$refs.video.player.duration,1));
      // resize the array, defaulting entries to zero
      console.log("resizing arrary to " + duration + " seconds.");
      this.watched = new Array(duration).fill(0)
    },
    roundUp(num, precision) {
      return Math.ceil(num * precision) / precision
    },
    async timeupdate() {
      var currentTime = parseInt(this.$refs.video.player.currentTime);
      console.log('timeupdate')
      // set the current second to "1" to flag it as watched
      this.watched[currentTime] = 1;

      // show the array of seconds so you can track what has been watched
      // you'll note that simply looping over the same few seconds never gets
      // the user closer to the magic 80%...
      console.log(this.watched);
      // sum the value of the array (add up the "watched" seconds)
      var sum = this.watched.reduce(function(acc, val) {return acc + val;}, 0);
      this.timer = sum
      if (sum % 10 == 0){
        await updateDoc(this.traineeId, {
          [this.video.id]: sum
        })
      }
    },
    async syncFirebase(){
      await updateDoc(this.traineeId, {
        [this.video.id]: this.timer
      })
    },
    async submitName(){
      this.nameForm = false
      if (this.name === '') {
        this.name = 'Guest'
      }
      const traineeData = {
        name: this.name
      }
      const docRef = await addDoc(collection(db, 'trainees'), traineeData)
      this.traineeId = docRef
    }
  }
}
</script>

<style>
.plyr--video {
  background-color: #ffffff !important;
}
</style>