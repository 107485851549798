import { createRouter, createWebHistory } from 'vue-router'
import Index from "@/components/Index.vue";
import Admin from "@/components/Admin.vue";
import Consent from "@/components/Consent.vue";

const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: Index
    }
  },
  {
    path: '/admin',
    name: 'admin',
    components: {
      default: Admin
    }
  },
  {
    path: '/consent',
    name: 'ConsentForm',
    components: {
      default: Consent
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
